import React from "react"

import Layout from "../components/layout"
import Poligono from "../components/heliofania/poligono"
import '../components/heliofania/styles.scss';

import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../components/SimpleLocalize";

import img01 from "../images/heliofania-01.jpg"

import poligono13imgelectroconductividad from "../images/poligonos/poligono_13_02.jpg"
import poligono13imgpoligono from "../images/poligonos/poligono_13_03.jpg"
import poligono13vinos from "../images/poligonos/vinos_poligono-13.jpg"

import poligono5imgelectroconductividad from "../images/poligonos/poligono5_02.jpg"
import poligono5imgpoligono from "../images/poligonos/poligono5_03.jpg"
import poligono5vinos from "../images/poligonos/vinos_poligono-5.jpg"

import poligono1imgelectroconductividad from "../images/poligonos/poligono1_02.jpg"
import poligono1imgpoligono from "../images/poligonos/poligono1_03.jpg"
import poligono1vinos from "../images/poligonos/vinos_poligono-1.jpg"


const IndexPage = (props) => {
	const { pageContext } = props;

	let camino = '..'
	let poslang = '';
    if(pageContext.language == 'en'){
        camino = '../..';
		poslang = "_en";
    }

	const poligonos = [
		{
			titulo: `<span>${pageContext.messages["poligono-trece"] }</span> <em>${pageContext.messages["malbec"] }</em>`,
			texto: pageContext.messages["nuestro-suelo-tipo-paraje-altamira"],
			ubicacion: { img: `${camino}/poligonos/poligono13_ubicacion_47${poslang}.jpg`, texto: pageContext.messages["pasaje-altamira-cuartel-47"] },
			electroconductividad: { img: poligono13imgelectroconductividad, texto: pageContext.messages["electrocunductividad-cuartel-cientocincuenta"] },
			poligono: { titulo: `${pageContext.messages["poligono-trece"]} - ${pageContext.messages["malbec"] }`, img: poligono13imgpoligono, texto: pageContext.messages["superficie1coma2"]  },
			vino: { titulo: pageContext.messages["vino"], img: poligono13vinos, texto: pageContext.messages["cien-por-cien-Malbec"] },
		},
		{
			titulo:  `<span>${pageContext.messages["poligono-cinco"] }</span> <em>${pageContext.messages["cabernet-franc"] }</em>`,
			texto: pageContext.messages["nuestro-suelo-de-la-consulta"],
			ubicacion: { img: `${camino}/poligonos/poligono5_ubicacion_26${poslang}.jpg`, texto: pageContext.messages["las-consulta-cuartel-veintiseis"] },
			electroconductividad: { img: poligono5imgelectroconductividad, texto: pageContext.messages["electrocunductividad-del-cuartel-cientoveinte"] },
			poligono: { titulo: `${pageContext.messages["poligono-cinco"]} - ${pageContext.messages["cabernet-franc"] }`, img: poligono5imgpoligono, texto: pageContext.messages["superficie-una-hectarea"]},
			vino: { titulo: pageContext.messages["vino"], img: poligono5vinos, texto: pageContext.messages["cien-por-cien-cabernet-franc"] }
		},
		{
			titulo: `<span>${pageContext.messages["poligono-uno"] }</span> <em>${pageContext.messages["cabernet-sauvignon"] }</em>`,
			texto: pageContext.messages["para-nuestro-suelo-de-eugenio-bustos"],
			ubicacion: { img: `${camino}/poligonos/poligonos_ubicacion_23${poslang}.jpg`, texto: pageContext.messages["eugenio-bustos-cuartel-veintitres"] },
			electroconductividad: { img: poligono1imgelectroconductividad, texto: pageContext.messages["electrocunductividad-del-cuartel-cientoveinte"]},
			poligono: { titulo: `${pageContext.messages["poligono-uno"]} - ${pageContext.messages["cabernet-sauvignon"] }`, img: poligono1imgpoligono, texto: pageContext.messages["superficie-una-ha-varietal"] },
			vino: { titulo: pageContext.messages["vino"], img: poligono1vinos, texto:pageContext.messages["cien-por-ciento-cabernet-sauvignon"]}
		},
	];

	return <Layout page='poligonos' {...props}>
		<SimpleLocalize {...props}>
			<section className="py-0 text-center position-relative min-vh-100">
				<div className="bg-holder header-video" style={{ backgroundImage: "url(" + img01 + ")" }}>
					<div className="video-wrapper position-absolute w-100 h-100">
						<iframe
							title="video introduction"
							frameBorder="0"
							height="100%"
							width="100%"
							src="https://youtube.com/embed/V4rCfjo3mJc?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=V4rCfjo3mJc&modestbranding=1"
						>
						</iframe>
					</div>
					<div className="min-vh-100 content-wrapper d-flex flex-column justify-content-center text-white container">
						<div style={{ "width": "100%", "height": "100vh", "background": "transparent", "position": "absolute", "left": "0", "zIndex": "1" }}></div>
						<h1 style={{ "zIndex": "2" }}><FormattedMessage id="poligonos-title" defaultMessage="poligonos-title" /></h1>
						<p style={{ "zIndex": "2" }} className="col-8 offset-2 text-center" dangerouslySetInnerHTML={{ __html: pageContext.messages["tres-vinos-tres-cepas"] }} />
						
					</div>
				</div>
			</section>
			<section className="py-0 text-center position-relative min-vh-100" id="seccion3">
				<div className="bg-repeater">
					<div className="container content-wrapper min-vh-83">
						<div className="row justify-content-around">
							<div className="min-vh-100 col-md-4 text-start ps-4 texto-con-scroll-mas-altura mt-6 pr-2">
								<h1 className="title-2"><FormattedMessage id="vinos-que-hablan-de-un-origen" defaultMessage="vinos-que-hablan-de-un-origen" /></h1>
								<hr />
								<p className="mt-4 me-3" dangerouslySetInnerHTML={{ __html: pageContext.messages["en-el-anio-dosmiltrece"] }} />
							</div>
							<div className="min-vh-100 col-md-8 img_height margin-top-poligono p-0 position-relative">
								<div className="position-relative">
									<img src={`${camino}/poligonos/lacelia_cuarteles${poslang}.jpg`} alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</SimpleLocalize>
		{poligonos.map((pol, index) => {
			return <Poligono key={index} {...props} dato={pol} />
		})}

	</Layout>
}

export default IndexPage