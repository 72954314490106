import React from 'react';
import backvinos from "../../images/back-vinos.jpg"
import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../../components/SimpleLocalize";

const Poligono = (props) => {
    const [itemSeleccionado, setItemSeleccionado] = React.useState('ubicacion');
    const zoomEstados = ['1', '1.5', '2', '2.5'];
    const [zoomEstadoSeleccionado, setzoomEstadoSeleccionado] = React.useState(0);

    const zoomIn = () => {
        console.log(zoomEstadoSeleccionado)
        if (zoomEstadoSeleccionado < zoomEstados.length - 1) {
            setzoomEstadoSeleccionado(estadoAnterior => estadoAnterior + 1)
        }
    }
    const zoomOut = () => {
        console.log(zoomEstadoSeleccionado)
        if (zoomEstadoSeleccionado > 0) {
            setzoomEstadoSeleccionado(estadoAnterior => estadoAnterior - 1)
        }
    }

    return (
        <SimpleLocalize {...props}>
            <section className="py-0 text-center position-relative min-vh-100"  >
                <div className="bg-repeater">
                    <div className="min-vh-83 content-wrapper container ">
                        <div className="row justify-content-around">
                            <div className="min-vh-100 col-md-4 text-start mt-6 p-0">
                                <h1 className="title-2" dangerouslySetInnerHTML={{ __html: props.dato.titulo }} />
                                <hr />
                                <div className="text-wrapper-scroll">
                                    {props.dato.texto && <p dangerouslySetInnerHTML={{ __html: props.dato.texto }} />}
                                    <div className="wine-info col-md-12">
                                        <div className="ubicacion">
                                            <h3 onClick={() => { setItemSeleccionado('ubicacion') }} className={itemSeleccionado == 'ubicacion' ? 'active p-0 pb-2' : 'p-0 pb-2'}><FormattedMessage id="ubicacion_lower_case" defaultMessage="ubicacion_lower_case" /> </h3>
                                            <p className={itemSeleccionado == 'ubicacion' ? 'd-block' : 'd-none'} dangerouslySetInnerHTML={{ __html: props.dato.ubicacion.texto }}></p>
                                        </div>
                                        <div className="electroconductividad">
                                            <h3 onClick={() => { setItemSeleccionado('electroconductividad') }} className={itemSeleccionado == 'electroconductividad' ? 'active p-0 pb-2' : 'p-0 pb-2'}><FormattedMessage id="electroconductividad-del-cuartel"defaultMessage="electroconductividad-del-cuartel" /> </h3>
                                            <p className={itemSeleccionado == 'electroconductividad' ? 'd-block' : 'd-none'} dangerouslySetInnerHTML={{ __html: props.dato.electroconductividad.texto }}></p>
                                        </div>
                                        <div className="tipo-suelo">
                                            <h3 onClick={() => { setItemSeleccionado('poligono') }} className={itemSeleccionado == 'poligono' ? 'active p-0 pb-2' : 'p-0 pb-2'}>{props.dato.poligono.titulo}</h3>
                                            <p className={itemSeleccionado == 'poligono' ? 'd-block' : 'd-none'} dangerouslySetInnerHTML={{ __html: props.dato.poligono.texto }}></p>
                                        </div>
                                        {props.dato.vino && <div className="vino">
                                            <h3 onClick={() => { setItemSeleccionado('vino') }} className={itemSeleccionado == 'vino' ? 'active p-0 pb-2' : 'p-0 pb-2'}>{props.dato.vino.titulo}</h3>
                                            <p className={itemSeleccionado == 'vino' ? 'd-block' : 'd-none'} dangerouslySetInnerHTML={{ __html: props.dato.vino.texto }}></p>
                                        </div>}

                                    </div>
                                </div>
                            </div>
                            <div className="min-vh-100 col-md-8 img_height margin-top-poligono p-0 position-relative">
                                <img src={props.dato.ubicacion.img} alt="" className={itemSeleccionado == 'ubicacion' ? 'd-block' : 'd-none'} />
                                <img src={props.dato.electroconductividad.img} alt="" className={itemSeleccionado == 'electroconductividad' ? 'd-block' : 'd-none'} />
                                <img src={props.dato.poligono.img} alt="" className={itemSeleccionado == 'poligono' ? 'd-block' : 'd-none'} style={{ 'transform': 'scale(' + zoomEstados[zoomEstadoSeleccionado] + ')' }} />
                                <img src={props.dato.vino.img} alt="" className={itemSeleccionado == 'vino' ? 'd-block' : 'd-none'} />
                                {itemSeleccionado == 'poligono' && <div className='poligono-menu-container'>
                                    <button className='zoom-in' onClick={zoomIn}></button>
                                    <button className='zoom-out' onClick={zoomOut}></button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </SimpleLocalize>
    );
};

export default Poligono;